/**
 * Create Account page
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Geturlparameter from "../components/geturlparameter"
import Iframecreateaccount from "../components/iframecreateaccount"
import Layoutwithoutfooter from "../components/layoutwithoutfooter"
import Seo from "../components/seo"
import "../styles/pages/create-account.scss"

/* Page function declaration */
const CreateaccountPage = ({ data, search }) => {
  const { key } = search

  //Const data ACF
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPageCreateaccount

  return (
    <Layoutwithoutfooter>
     <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
        classbody="no-cookies"
      />

      {/* Iframe content */}
      <section className="section-signup iframe-section">
        <h1 className="title-level-1">{dataAcf.titleGeneralCreateAccount}</h1>
        <div className="box-content" dangerouslySetInnerHTML={{ __html:dataAcf.contentGeneralCreateAccount}} />
        <Iframecreateaccount paramKey= {key} />
      </section>
    </Layoutwithoutfooter>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "create-account"}) {
      acfPageCreateaccount {
        titleGeneralCreateAccount
        contentGeneralCreateAccount
      }
      seo {
        metaDesc
        title
      }
    }
  }
`

/* Export page informations */
export default Geturlparameter(CreateaccountPage)
