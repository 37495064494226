/**
 * Const with a param element
 * Iframe Create Account component
 * Display the iframe of Create Account
 */
/* Import section */
import React, { Component } from "react"
import PropTypes from "prop-types"


/* Declaration function */
class Iframecreateaccount extends Component {

  constructor(props) {
    super(props)
    this.state = {
      keyCreateAccount: this.props.paramKey,
      srcCreateAccount: ''
    }
  }

  componentDidMount () {
    (this.state.keyCreateAccount !== undefined) ? (
      this.setState({
        keyCreateAccount: this.props.paramKey,
        //srcCreateAccount: 'https://platform.hellohealth.com/login/?key='+ this.state.keyCreateAccount + '#signUpPortalConnect'
        //srcCreateAccount: 'https://platform.hellohealth.com/login/#signUpPortalConnect?key='+ this.state.keyCreateAccount
        srcCreateAccount: 'https://platform.hellohealth.com/hhweb/apps/authentication/#signUpPortalConnect?key='+ this.state.keyCreateAccount
        //srcCreateAccount: 'https://dev7.myca.io/hhweb/apps/authentication/?key='+ this.state.keyCreateAccount + '#signUpPortalConnect'
        //srcCreateAccount: 'https://dev7.myca.io/hhweb/apps/authentication/#signUpPortalConnect?key='+ this.state.keyCreateAccount
      })
    ) : (
      this.setState({
        keyCreateAccount: this.props.paramKey,
        //srcCreateAccount: 'https://platform.hellohealth.com/login/?ac=2#signUpPortalConnect'
        //srcCreateAccount: 'https://platform.hellohealth.com/login/#signUpPortalConnect?ac=2'
        srcCreateAccount: 'https://platform.hellohealth.com/hhweb/apps/authentication/#signUpPortalConnect?ac=2'
        //srcCreateAccount: 'https://dev7.myca.io/hhweb/apps/authentication/?ac=2#signUpPortalConnect'
        //srcCreateAccount: 'https://dev7.myca.io/hhweb/apps/authentication/#signUpPortalConnect?ac=2'
      })
    )
  }


  render () {
    var src = this.state.srcCreateAccount

    return (
      <iframe className="iframe-signup" name="signUp" src={src} scrolling="no" title="create-account"></iframe>
    )
  }
}

/* Function propTypes declaration */
Iframecreateaccount.propTypes = {
  paramkey: PropTypes.string
}

/* Export function */
export default Iframecreateaccount
